import React from 'react';
import { navigate } from 'gatsby';
import { Layout } from 'src/components/Layout';
import styles from './styles.module.css';
import { Spinner } from 'src/components/Spinner';
import { ArrowButton } from 'src/components/ArrowButton/component';
import { UserManager } from 'src/helpers/UserManager';
import { message, Checkbox } from 'antd';

interface Props {}

interface State {
	loading: boolean;
	userId: { id: number };
	success: boolean;
	id: number;
	profileVisibilityStaff?: boolean;
	profileVisibilityEmployers?: boolean;
	profileURL?: string;
	changed: boolean;
}

export default class ProfileDetails extends React.Component<Props, State> {
	state: State = {
		loading: true,
		success: false,
		id: 0,
		userId: { id: 0 },
		changed: false,
	};

	async componentDidMount() {
		const user = await UserManager.getAuthenticatedUser();
		if (!user || user.guest) {
			// no user found so send them to sign in
			navigate('/sign-in?url=profile-settings');
			return;
		}

		// TODO We need to read profileVisibilityStaff & profileVisibilityEmployers

		this.setState({
			loading: false,
			success: false,
			userId: { id: user.id ?? 0 },
		});
	}

	updateProfile = async () => {
		// TODO We need to save profileVisibilityStaff & profileVisibilityEmployers
	};

	goToProfileScreen = () => {
		navigate('/profile');
	};

	render() {
		const { loading, profileURL, changed } = this.state;

		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<div>
							<div className={styles.header}>
								<div className={styles.bookend}>
									<ArrowButton
										isBackButton={true}
										caption={'Back'}
										onClick={this.goToProfileScreen}
									/>
								</div>
								<h1>Profile Settings</h1>
								<div className={styles.bookend}></div>
							</div>

							<hr />

							<div
								hidden={this.state.profileVisibilityStaff || this.state.profileVisibilityEmployers}
								className={styles.detailsContainer}
							>
								Your profile is currently private. Turn on the sharing settings that suit you to
								allow others to view it
							</div>

							<div className={styles.row}>
								<Checkbox
									checked={this.state.profileVisibilityStaff}
									onChange={(e) =>
										this.setState({ profileVisibilityStaff: e.target.checked, changed: true })
									}
								>
									Share my profile with Xceptional team members
								</Checkbox>
							</div>
							<div className={styles.row}>
								<p className={styles.paragraph}>
									This setting will allow Xceptional staff to review your profile and offer advice
								</p>
							</div>

							<div className={styles.row}>
								<Checkbox
									checked={this.state.profileVisibilityEmployers}
									onChange={(e) =>
										this.setState({ profileVisibilityEmployers: e.target.checked, changed: true })
									}
								>
									Share my profile with potential employers
								</Checkbox>
							</div>
							<div className={styles.row}>
								<p className={styles.paragraph}>
									This setting will allow Xceptional staff to share your profile with potential
									employers looking for candidates like yourself
								</p>
							</div>

							<hr />

							<div className={styles.row}>
								{profileURL ? (
									<div className={styles.column}>
										<h3>Share via PDF:</h3>{' '}
										<a href={profileURL} className={styles.activeElements}>
											{'DOWNLOAD PDF NOW'}
										</a>
									</div>
								) : null}
							</div>

							<div className={styles.footer}>
								<hr className={styles.hr} />
								<ArrowButton
									isBackButton={false}
									isDisabled={!changed}
									caption={'Save'}
									onClick={this.updateProfile}
								/>
							</div>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
